import axios from "axios";

if (document.location.hostname === "localhost") {
  axios.defaults.baseURL = "http://localhost:5000";
} else if (document.location.hostname === "ortusitsolutions.com") {
  axios.defaults.baseURL = "https://server.ortusitsolutions.com";
} else {
  axios.defaults.baseURL = "https://server.ortusitsolutions.com";
}

export default axios;
